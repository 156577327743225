<template>
  <div>
    <HTML :content="content.intro" />
    <div class="hv-tiles">
      <div class="hv-tiles__tile" v-for="(item, index) in content.tiles" :key="index">
        <img class="hv-tiles__icon" :src="require('../../assets/image/ui/' + item.icon)">
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import HTML from './HTML'

export default {
  props: ['content'],
  components: {
    HTML
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'
@require '../../assets/style/1-settings/layout'
@require '../../assets/style/1-settings/typography'
@require '../../assets/style/2-tools/responsive'

.hv-tiles
  text-align center
  display flex
  width 100%
  flex-wrap wrap

  &__tile
    flex-grow 1
    display flex
    justify-content center
    align-items center
    flex-direction column
    min-height 90px
    responsive(
      height 78px,
      height 110px
    )

    padding 0.5em
    flex-basis 32%
    height 33vw


    p
      color rgba(45, 10, 17, 0.8)
      margin-top 0.5em
      font-family $fontBold
      font-size 13px
      line-height 1
      margin-bottom 0

  &__icon
    height 40px
    width 100%

</style>
