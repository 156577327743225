<template>
<div class="hv-page">
  <div class="hv-page__body">
    <div class="hv-page__btn-close" @click="$router.go(-1)"><img src="../assets/image/ui/btn-overlay-close.svg"></div>
    <div class="hv-page__content">
      <div
        :class="['hv-page__block', {
          'is-inside': index > 0 && index < page.blocks.length - 1,
          'is-first': index == 1
        }]"
        v-for="(block, index) in page.blocks"
        :key="$route.params.topic + $route.params.name + index"
        v-once>
        <component :is="block.type" :content="block.content" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { TweenLite, CSSPlugin, Power2 } from 'gsap/all' // eslint-disable-line no-unused-vars
import content from '../mixins/content'
import Accordeon from '../components/blocks/Accordeon'
import Tiles from '../components/blocks/Tiles'
import Tabs from '../components/blocks/Tabs'
import HTML from '../components/blocks/HTML'
import 'gsap-then'

export default {
  mixins: [content],
  components: {
    Accordeon,
    Tiles,
    Tabs,
    HTML
  },
  computed: {
    currentPageId() {
      switch (this.$route.name) {
        case 'topic-faq':
          return 'faq'
        case 'topic-causes':
          return 'causes'
      }
      return false
    },
    page() {
      return this.c.topics[this.$route.params.topic][this.currentPageId]
    }
  },
  methods: {
    enter(onUpdateCallback, noTransition = false) {
      console.log('TopicPage enter')
      let offset = 100
      let duration = noTransition ? 0.01 : 0.8
      return TweenLite.fromTo(this.$el, duration,
        { y: offset + '%' },
        { y: '0%', ease: Power2.easeOut, onUpdate: onUpdateCallback, onUpdateParams: [this.$el] }
      )
    },
    leave(onUpdateCallback, noTransition = false) {
      console.log('TopicPage leave')
      let offset = 100
      let duration = noTransition ? 0.01 : 0.8
      return TweenLite.fromTo(this.$el, duration,
        { y: '0%' },
        { y: offset + '%', ease: Power2.easeIn, onUpdate: onUpdateCallback, onUpdateParams: [this.$el] }
      )
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'
@require '../assets/style/1-settings/colors'

// Mobile layout

+mobile()
  .route-topic-faq,
  .route-topic-causes
    .hv-stage,
    .hv-topic__inner
      display none

    .hv-topic__child
      position static
      overflow auto

    .c-slideout
      bottom auto
      top 70px

  .route-topic-faq .c-slideout--first,
  .route-topic-causes .c-slideout--second
      display none

// End mobile layout

.hv-page
  text-align center
  padding-top 3rem

  +above('m')
    padding-top 10rem

  h1
    font-size 24px
    margin-bottom 0.5em

  &__btn-close
    display none
    position absolute
    top 1.2rem
    left 50%
    transform translateX(-50%)
    width 30px
    height 30px
    cursor pointer

    +mobile()
      display block

  &__body
    height 100%
    overflow-y scroll
    position relative

  &__content
    background-image linear-gradient(#E93842 0%, #E93842 19%, #F9CDCF 81%, #F9CDCF 100%)
    min-height 100%
    padding-bottom 3rem

    .is-flipped &
      background-image linear-gradient(#578DD0 0%, #578DD0 19%, #D5E2F3 81%, #D5E2F3 100%)

    +mobile()
      padding-top 2rem

  &__block
    margin 0 auto
    margin-bottom 2rem
    max-width $siteMaxWidth
    margin 0 25px

    &:first-child
      margin 0

      h1, p
        color white !important

    &:last-child
      margin 0

  &__close
    position absolute
    top 0
    left 50%
    background white
    border none
    padding 10px 16px
    transform translate(-50%, 0)
    cursor pointer

</style>
