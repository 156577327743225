<template>
  <div class="hv-tabs">
    <div class="hv-tabs__header">
      <div class="hv-tabs__tab"
        :class="{ 'is-open': (currentOpenItem == index) }"
        @click="onClick(index)"
        v-for="(item, index) in content" :key="index">
          <img class="hv-tabs__icon" :src="require('../../assets/image/ui/' + item.icon)">
          <p v-html="item.title"></p>
      </div>
    </div>
    <div class="hv-tabs__body">
      <div v-for="(item, index) in content" :key="index" v-show="currentOpenItem == index">
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['content'],
  data() {
    return {
      currentOpenItem: 0
    }
  },
  methods: {
    onClick(index) {
      this.currentOpenItem = index
    }
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'
@require '../../assets/style/1-settings/layout'
@require '../../assets/style/1-settings/typography'
@require '../../assets/style/2-tools/responsive'

.hv-tabs

  .is-first &
    border-radius 25px 25px 0 0
    overflow hidden

  &__header
    cursor pointer
    display flex
    width 100%

  &__icon
    display block
    max-width 90px
    height auto
    margin-bottom 0.8rem
    width 90%

  &__tab
    position relative
    flex-grow 1
    flex-basis 50%
    display flex
    flex-direction column
    justify-content center
    align-items center
    padding 1em
    overflow hidden
    min-height 190px
    background-color rgba(255, 255, 255, 0.4)
    opacity 0.6

    &:nth-child(2n)
      opacity 0.8

    &.is-open
      background-color rgba(255, 255, 255, 0.5)
      opacity 1

      &:after
        content ''
        display block
        position absolute
        bottom 0
        left 50%
        transform translate(-50%, 50%) scaleY(0.7) rotate(45deg)
        width 18px
        height 18px
        background-color white

    p
      margin 0
      font-family $fontBold
      font-size responsive 14px 17px
      line-height 1.1

      .is-first &
        color white

  &__body
    text-align center
    padding 2em
    padding-bottom 0.5em
    background white
</style>
