<template>
<div class="hv-root">
  <header-bar/>
  <main class="hv-main">
    <div class="hv-imprint  o-panel">
      <div class="hv-imprint__inner">
        <h1 class="u-h1">Impressum</h1>
        <h2 class="u-h3">Bristol-Myers Squibb GmbH & Co. KGaA</h2>
        <div class="hv-imprint__cols">
          <p>Geschäftsführer<br>
          Han Steutel<br>
          Arnulfstr. 29<br>
          80636 München<br>
          Tel.: (089) 1 21 42-0<br>
          <a href="http://www.auf-herz-und-venen.de/">www.auf-herz-und-venen.de</a><br><br>
          Registergericht München HRB 154472<br>
          Umsatzsteueridentifikations-Nr.: DE 129308813</p>
          <p>Aufsichtsbehörde:<br>
          Regierung von Oberbayern<br>
          Maximilianstraße 39<br>
          80538 München<br><br>
          Projektleitung:<br>
          Bristol-Myers Squibb GmbH & Co. KGaA<br>
          <a href="mailto:medwiss.info@bms.com">medwiss.info@bms.com</a></p>
        </div>
        <p>Die auf dieser Website bereitgestellten Informationen sind dazu bestimmt, allgemeine Unternehmens- oder institutionelle Informationen über Bristol-Myers Squibb Company und/oder verbundene Unternehmen in der Bundesrepublik Deutschland für die Öffentlichkeit bereitzustellen. Jegliche sich auf Krankheiten oder Symptome beziehende Angaben sind allgemeiner Natur und sollen in keiner Weise die Beratung durch einen Angehörigen der medizinischen Fachkreise ersetzen. Wenn Sie spezifische Informationen zu einer Erkrankung und/ oder zu ihrer Erkennung, Diagnose und Prognose, zur Einnahme von Arzneimitteln und gegebenenfalls zur geeigneten Behandlung in jedem spezifischen Fall wünschen, wenden Sie sich bitte umgehend an einen Arzt. Die Nutzung dieser Website unterliegt den Bestimmungen der Rechtlichen Hinweise, der Datenschutzrichtlinie, dem Pharmakovigilanz-Hinweis sowie den geltenden Gesetzen und Vorschriften.</p>
      </div>
    </div>
  </main>
  <Navigation/>
  <Footer/>
</div>

</template>

<script>
import HeaderBar from '../components/HeaderBar'
import Navigation from '../components/Navigation'
import Footer from './Footer'

export default {
  components: {
    HeaderBar,
    Navigation,
    Footer
  }
}
</script>

<style lang="stylus">
.hv-main
  min-height 90vh

.hv-imprint
  margin-top 10rem
  margin-bottom 4rem

  .u-h1
    margin-bottom 0.5em
  .u-h3
    margin-bottom 2em

  a
    text-decoration underline
    font-weight 700

  &__inner
    background #fff
    text-align center
    padding 3rem 4rem
    display flex
    flex-direction column
    justify-content center
    align-items center

  &__cols
    display flex
    text-align left
    justify-content space-between
    margin-bottom 2em
    +mobile()
      p
        width 45%
    +above('m')
      width 80%

</style>
