<template>
  <footer class="hv-footer">
    <div class="hv-footer__bar">
      <div class="hv-footer__logo-wrapper">
        <img class="hv-footer__logo" src="../assets/image/footer/footer-logos.svg">
      </div>
      <ul class="hv-footer__nav">
        <li><a href="https://www.bms.com/de/about-us/contact-us.html" target="_blank">Kontakt</a></li>
        <li><router-link to="/impressum">Impressum</router-link></li>
        <li><a href="https://www.bms.com/de/privacy-policy.html" target="_blank">Datenschutzrichtlinie</a></li>
        <li><a href="https://www.bms.com/de/legal-notice.html" target="_blank">Rechtliche Hinweise</a></li>
        <li><a href="https://www.bms.com/de/pharmacovigilance.html" target="_blank">Pharmakovigilanz-Hinweis</a></li>
        <li><a href="https://www.bms.com/de/cookies.html" target="_blank">Cookie-Beschreibung</a></li>
      </ul>
    </div>
    <div class="hv-footer__legal">
      <p v-html="c.footer.legal"></p>
    </div>
  </footer>
</template>

<script>
import content from '../mixins/content'

export default {
  mixins: [content]
}
</script>
<style lang="stylus">
@require '../assets/style/1-settings/typography'

$footerBarHeight = 3rem
$footerBreakpoint = 1095px

.hv-footer
  &__legal
    padding 1rem 1rem
    background-color #F0EBED

    > p
      max-width 800px
      margin 0 auto
      color #7d7d7d !important
      font-size 13px !important
      line-height 24px
      font-family $fontRegular !important

    a
      text-decoration underline
      font-size 13px !important
      font-weight 100 !important
      color #7d7d7d !important

  &__bar
    background white
    height $footerBarHeight
    display flex
    justify-content space-between
    align-items center

    +above('m')
      padding 0 3rem

    +below($footerBreakpoint)
      height auto
      flex-direction column-reverse

  &__logo-wrapper
    +above($footerBreakpoint)
      width 30%
    
  &__logo
    width 300px
    height 30px
    margin-right 1rem
    margin-top 2px
    +below($footerBreakpoint)
      margin-right 0
      margin-bottom 1em

  &__nav
    display flex
    flex-wrap wrap

    +above($footerBreakpoint)
      width 70%
      justify-content space-between

    +below($footerBreakpoint)
      justify-content center
      padding 1em 0
      li
        padding 0.8em 0

    li
      display block
      margin 0 0.5rem
      line-height 1
      margin-top -5px

      a
        color black
        font-size 13px
        font-family $fontBold
        line-height 1
        white-space nowrap
</style>
