<template>
  <transition name="fade-short">
    <div class="hv-navigation" v-show="isNavigationOpen" @click="onClick">
      <ul class="hv-navigation__main">
        <li class="hv-navigation__item">
          <Bubble type="vte" caption="1"/>
          <ul>
            <li class="hv-navigation__subitem"><router-link to='/venenthrombose/ursachen-und-folgen'><p>{{ c.common.causes }}</p></router-link></li>
            <li class="hv-navigation__subitem"><router-link to='/venenthrombose/haeufige-fragen'><p>{{ c.common.faq }}</p></router-link></li>
          </ul>
        </li>
        <li class="hv-navigation__item">
          <Bubble type="spaf" caption="1"/>
          <ul>
            <li class="hv-navigation__subitem"><router-link to='/vorhofflimmern/ursachen-und-folgen'><p>{{ c.common.causes }}</p></router-link></li>
            <li class="hv-navigation__subitem"><router-link to='/vorhofflimmern/haeufige-fragen'><p>{{ c.common.faq }}</p></router-link></li>
          </ul>
        </li>
      </ul>
      <div class="hv-navigation__footer">
        <a href="https://www.eliquis.de/" target="_blank">Zur Fachkreis Seite</a>
      </div>
      <Footer/>
    </div>
  </transition>
</template>

<script>
import content from '../mixins/content'
import Footer from '../views/Footer'
import Bubble from './ui/Bubble'

export default {
  mixins: [content],
  components: {
    Footer,
    Bubble
  },
  computed: {
    isNavigationOpen() {
      return this.$store.state.isNavigationOpen
    }
  },
  methods: {
    onClick(event) {
      this.$store.commit('closeNavigation')
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'
@require '../assets/style/1-settings/typography'
@require '../assets/style/2-tools/responsive'

.hv-navigation
  position fixed
  top 0
  right 0
  background #e0e0e0
  z-index 10
  height 100%
  width 100%
  overflow scroll

  @media (min-height 700px)
    display flex
    flex-direction column

  p,a
    color white
    font-family $fontBold
    font-size responsive 16px 20px
    font-range $phoneWidth $desktopMaxWidth
    text-align center

  &__main
    list-style-type none
    display flex
    flex-grow 1

    .ios-10 &,
    .ios-9 &,
    .ios-8 &
      height 80%

  &__item
    width 50%
    padding-top $headerBarHeight + 20%
    display flex
    flex-direction column
    justify-content space-between

    +mobile()
      padding-top $headerBarHeightMobile + 20

    > ul
      list-style-type none
      +above('m')
        display flex
        flex-basis 40%
      +below('m')
        flex-direction column
    > a
      height 100%
      display block
      .hv-bubble
        p
          font-size responsive 13px 18px
        responsive(
          (width 150px) \
          (height 150px),
          (width 230px) \
          (height 230px)
        )
      +above('s')
        display flex
        flex-direction column
        justify-content center
        align-items center
        flex-grow 1
      +above('l')
        min-height 350px


    &:first-child
      background-image linear-gradient(-46deg, #C1BEFF 0%, #E4F2FE 93%)

      .hv-navigation__subitem
        &:first-child
          background-color rgba(62,146,246,0.4)
        &:last-child
          background-color rgba(62,146,246,0.3)

    &:last-child
      background-image linear-gradient(-135deg, rgba(255,218,218,0.46) 0%, #FF9292 100%)
      .hv-navigation__subitem
        &:first-child
          background-color rgba(237,92,86,0.4)
        &:last-child
          background-color rgba(237,92,86,0.3)

  &__subitem
    height 55px
    +above('m')
      min-height 55px
      flex-grow 1
    +above('l')
      flex-grow 0
      width 50%
      height inherit
    > a
      display flex
      justify-content center
      align-items center
      height 100%
      > p
        margin 0

  &__footer
    text-align center
    color #7b7b7b
    > a
      color gray
      font-family $fontRegular
      margin 0
      padding 0.5em
      line-height 3
    & + .hv-footer
      margin-top 0

</style>
