const mqNarrow = window.matchMedia('(max-width: 700px)')

export default {
  methods: {
    isNarrow() {
      return mqNarrow.matches
    },
    addMediaQueryListener(handler) {
      mqNarrow.addListener(handler)
    },
    removeMediaQueryListener(handler) {
      mqNarrow.removeListener(handler)
    }
  }
}
