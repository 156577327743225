<template>
<div id="hv-app">
  <transition name="fade-long" mode="out-in">
    <spinner v-if="!preloadComplete" />
    <template v-else="preloadComplete">
      <keep-alive>
        <router-view :class="'route-' + $route.name"/>
      </keep-alive>
    </template>
  </transition>
</div>
</template>

<script>
import Spinner from './components/Spinner'
import organCanvas from './components/OrganCanvas'
import content from './content'
import axios from 'axios'

export default {
  data() {
    return {
      preloadComplete: false
    }
  },
  components: { Spinner },
  mounted() {
    Promise.all([organCanvas.initialize(), axios.get('data/content.json')])
      .then(([canvasResult, contentResult]) => {
        content.data = contentResult.data
        this.preloadComplete = true
      })
      .catch(error => {
        console.log('Error while preloading!', error)
      })
  }
}
</script>

<style lang="stylus">
@require './assets/style/main.styl'

#hv-app
  min-height 100vh
  height 100%
  background #eff6f7

#hv-orientation-lock
  position absolute
  width 100%
  height 100vh
  background black
  pointer-events none
  z-index 999
  display none
  opacity 0.9
  align-items center
  text-align center

  h2
    color white
    width 80%
    margin 0 auto

</style>
