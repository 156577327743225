import 'scrolling-element'
import iOSVersion from 'ios-version'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'

Vue.config.devtools = true
Vue.config.performance = true
Vue.config.productionTip = false

let iosv = iOSVersion(window.navigator.userAgent)
if (iosv) {
  document.querySelector('html').classList.add('ios-' + iosv.major)
}

window.hvTouchDevice = false

window.addEventListener('touchstart', function touchedForTheVeryFirstTime()  {
  window.hvTouchDevice = true
  document.querySelector('html').classList.add('is-touch')
  window.removeEventListener('touchstart', touchedForTheVeryFirstTime)
}, false)

sync(store, router)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#hv-app')
