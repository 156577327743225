<template>
  <div :class="['hv-topic-details__outer', { 'hv-topic-details--flipped': (isFlipped) }]">
    <div class="hv-topic-details" ref="topicDetailsContainer">
      <div class="hv-topic-detail" :ref="'step' + index"
        v-for="(topicDetail, index) in topicDetails"
        :class="{ 'is-current': index == currentStep - 1}"
        :key="index">
        <div class="hv-topic-detail__inner o-panel">
          <div class="hv-topic-detail__video">
            <img :src="videoSourcePath + topicDetail.video + '.gif'">
          </div>
          <h1 class="u-h1">{{ topicDetail.headline }}</h1>
          <p>{{ topicDetail.copy }}</p>
        </div>
      </div>
    </div>

    <transition name="slideout-transition">
      <div class="c-slideout-icon c-slideout-icon--large" v-if="currentDetail.more" ref="slideoutHandle">
        <div class="c-slideout-icon__inner">
          <span></span>
          <router-link class="c-slideout-icon__btn" :to="moreLink" ref="slideoutHandle" replace>
            <span class="c-slideout-icon__icon"><img :src="currentDetail.more.icon"></span>
          </router-link>
          <span></span>
        </div>
        <span class="c-slideout-icon__label">{{ currentDetail.more.title }}</span>
      </div>
    </transition>

    <transition mode="out-in" @enter="childEnter" @leave="childLeave">
      <router-view class="hv-topic-detail__more" ref="routerView"></router-view>
    </transition>
    <router-link class="hv-topic-detail__back" :to="{ name: 'topic', params: { topic: ($route.params.topic) }}">
      <svg width="11" height="17" xmlns="http://www.w3.org/2000/svg"><path d="M9.475 1.078l-7.477 7.35 7.477 7.35" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg>
    </router-link>
  </div>
</template>

<script>
import { TweenLite, CSSPlugin, Power2 } from 'gsap/all' // eslint-disable-line no-unused-vars
import content from '../mixins/content'
import device from '../mixins/device'
import throttle from 'lodash.throttle'
import animateScrollTo from 'animated-scroll-to'

export default {
  mixins: [content, device],
  mounted() {
    let container = this.$refs.topicDetailsContainer
    this.$options.stepMidpoints = []
    this.gotoStep(this.$route.params.step, true)
    this.$options.throttledScrollHandler = throttle(
      this.onScroll.bind(this),
      50
    )
    container.addEventListener('scroll', this.$options.throttledScrollHandler)
    this.recalc()
  },
  beforeDestroy() {
    this.$refs.topicDetailsContainer.removeEventListener(
      'scroll',
      this.$options.throttledScrollHandler
    )
  },
  data() {
    return {
      currentStep: 1,
      lockScroll: false
    }
  },
  computed: {
    isFlipped() {
      return this.$store.getters.isFlipped
    },
    moreLink() {
      if (this.$route.name == 'topic-detail') {
        return { name: 'topic-detail-more', params: { topic: this.$route.params.topic, step: this.$route.params.step }}
      } else {
        return { name: 'topic-detail', params: { topic: this.$route.params.topic, step: this.$route.params.step }}
      }
    },
    organHealthMode() {
      return this.$store.state.showHealthy ? 'healthy' : 'affected'
    },
    topicDetails() {
      let topic = this.$route.params.topic
      return this.c.topics[topic][this.organHealthMode].details
    },
    videoSourcePath() {
      return 'assets/animations/detail/' + this.$store.getters.currentTopic + '/' + this.organHealthMode + '/'
    },
    currentDetail() {
      return this.topicDetails[this.currentStep - 1]
    }
  },
  methods: {
    gotoStep(step, immediately = false) {
      this.lockScroll = true

      let $container = this.$refs.topicDetailsContainer
      let $targetStep = this.$refs['step' + (step - 1)][0]
      if (!$targetStep) return
      let rect = $targetStep.getBoundingClientRect()
      let scrollTarget = $container.scrollTop + rect.top

      if (immediately) {
        $container.scrollTop = scrollTarget
        this.lockScroll = false
        this.currentStep = step
        return
      }

      animateScrollTo(scrollTarget, {
        element: $container,
        cancelOnUserAction: false,
        speed: 500,
        onComplete: () => {
          this.lockScroll = false
          this.currentStep = step
        }
      })
    },
    onScroll(event) {
      if (this.lockScroll) return

      let container = event.target
      let containerRect = container.getBoundingClientRect()
      let viewportMidpoint =
        container.scrollTop + containerRect.top + containerRect.height * 0.5
      let closestStep = 0
      let closestDelta = 1000
      for (let i = 0; i < this.$options.stepMidpoints.length; i++) {
        let stepMidpoint = this.$options.stepMidpoints[i]
        if (Math.abs(stepMidpoint - viewportMidpoint) < closestDelta) {
          closestDelta = Math.abs(stepMidpoint - viewportMidpoint)
          closestStep = i
        }
      }

      let potentialStep = closestStep + 1 // convert from zero-indexed
      if (this.currentStep == potentialStep) return

      this.currentStep = potentialStep
      this.$router.replace({
        name: 'topic-detail',
        params: { topic: this.$route.params.topic, step: potentialStep }
      })
    },
    recalc() {
      this.$options.stepMidpoints = []
      for (let i = 0; i < this.topicDetails.length; i++) {
        let $step = this.$refs['step' + i][0]
        let rect = $step.getBoundingClientRect()
        let midpoint =
          this.$refs.topicDetailsContainer.scrollTop +
          rect.top +
          rect.height * 0.5
        this.$options.stepMidpoints[i] = midpoint
      }
    },
    onResize() {
      this.recalc()
    },
    enter() {
      console.log('TopicDetails enter')
      let offset = this.$store.getters.isFlipped ? -100 : 100
      return TweenLite.fromTo(this.$el, 0.8, { x: offset + '%' }, { x: '0%', ease: Power2.easeOut })
    },
    leave() {
      console.log('TopicDetails leave')
      let offset = this.$store.getters.isFlipped ? -100 : 100
      return TweenLite.fromTo(this.$el, 0.8, { x: '0%' }, { x: offset + '%', ease: Power2.easeIn })
    },
    childEnter(el, done) {
      // Remove slideout handle bottom position, set top position based on current layout
      let handleBounds = this.$refs.slideoutHandle.getBoundingClientRect()
      this.handleHeight = handleBounds.bottom - handleBounds.top
      TweenLite.set(this.$refs.slideoutHandle, { top: handleBounds.top + 'px', bottom: 'auto' })
      this.$refs.routerView.enter(this.updateHandlePosition, this.isNarrow()).then(done)
    },
    childLeave(el, done) {
      this.$refs.routerView.leave(this.updateHandlePosition, this.isNarrow()).then(() => {
        TweenLite.set(this.$refs.slideoutHandle, { top: 'auto', bottom: '0px' })
        done()
      })
    },
    updateHandlePosition($page) {
      let childPageBounds = $page.getBoundingClientRect()
      let handleTargetPosition = childPageBounds.top - this.handleHeight + document.scrollingElement.scrollTop
      TweenLite.set(this.$refs.slideoutHandle, { top: handleTargetPosition + 'px' })
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('TopicDetails beforeRouteEnter')
    next()
  },
  beforeRouteLeave(to, from, next) {
    console.log('TopicDetails beforeRouteLeave')
    next()
  },
  watch: {
    $route(to) {
      if (to.name !== 'topic-detail') return
      this.recalc() // TODO: Don't recalc on EVERY route change (includes step changes)
      let requestedStep = to.params.step
      if (requestedStep != this.currentStep) {
        this.gotoStep(requestedStep)
      }
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'

.hv-topic-details
  background white
  height 100%
  overflow-y scroll
  -webkit-overflow-scrolling touch
  padding-top 0
  padding-bottom 0

  &__outer
    z-index 5

.hv-topic-detail
  position relative
  min-height calc(100vh - 3rem) // 3rem == footer bar height
  height 0 // IE needs height value set for flexbox containers
  display flex
  flex-direction column
  align-items center

  +above('m')
    justify-content center

  +mobile()
    // min-height "calc(100vh - %s)" % $headerBarHeightMobile
    min-height 100vh
    text-align left

    &.is-current
      padding-top $headerBarHeightMobile

  +at('s')
    &:not(.is-current)
      overflow-y hidden
      min-height 0
      height 0

  &__inner
    margin 0 auto
    max-width 720px

    +mobile()
      padding 0 2.5rem

  &__video
    position relative
    display block
    margin-left auto
    margin-right auto
    width 80%
    max-width 200px
    margin-bottom 2rem
    border-radius 100%
    overflow hidden
    border 10px solid #FFFFFF
    box-shadow 0 11px 10px 0 rgba(0,0,0,0.05)
    background-color #f8f8f8

    @media (max-height 500px) and (orientation portrait)
      margin-bottom 1rem
      max-width 160px

    @media (max-height 767px) and (orientation landscape)
      margin-bottom 1.25rem
      max-width 180px

    &:before
      position relative
      display block
      content ''
      width 100%
      padding-top 100%

    > img
      position absolute
      top 0
      left 0
      bottom 0
      right 0
      width 100%

  &__more
    z-index 6

  &__more-btn
    > a
      border-bottom 1px dotted currentColor

  &__back
    display block
    position absolute
    top calc(50% - 1rem)
    left 0
    width 35px
    height 70px
    transform translate3d(0, -50%, 0)
    overflow hidden

    +above('m')
      display none

    &:before
      content ''
      display block
      position absolute
      transform translate(-50%, 0)
      padding 15px
      border-radius 100%
      width 70px
      height 70px
      background-color #E93842

    svg
      width auto
      height auto
      position absolute
      top 50%
      left 50%
      transform translate(-115%, -50%)

    .hv-topic-details--flipped &
      left auto
      right 0
      transform translate3d(0, -50%, 0)

      &:before
        background-color #578DD0
        transform none

      svg
        transform translate(15%, -50%) rotate(180deg)
</style>
