<template>
<div class="hv-organ" :class="{ 'is-magnified': isMagnifierActive }">
  <div class="hv-organ__container" ref="container"></div>
</div>
</template>

<script>
import organCanvas from './OrganCanvas'

export default {
  props: {
    showHealthy: Boolean,
    topic: String
  },
  computed: {
    isMagnifierActive() {
      return this.$store.state.isMagnifierActive
    }
  },
  created() {
    console.log('Organ created')
    organCanvas.showHealthy = this.showHealthy
    organCanvas.topic = this.topic
    organCanvas.on('deactivate-magnifier', this.deactivateMagnifier.bind(this))
  },
  mounted() {
    console.log('Organ mounted')
    organCanvas.mount(this.$refs.container)
  },
  methods: {
    start() {
      organCanvas.start()
    },
    stop() {
      organCanvas.stop()
    },
    deactivateMagnifier() {
      this.$store.commit('setMagnifierActive', false)
    },
  },
  watch: {
    showHealthy(newValue) {
      organCanvas.showHealthy = newValue
      organCanvas.render()
    },
    '$store.state.isMagnifierActive'(activate) {
      if (activate) {
        organCanvas.activateMagnifier()
      } else {
        organCanvas.deactivateMagnifier()
      }
      organCanvas.render()
    },
    topic(newValue) {
      organCanvas.topic = newValue
      organCanvas.render()
    }
  }
}
</script>
<style lang="stylus">
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/layout'

.hv-organ
  width inherit

  &__container,
  canvas
    width inherit

  &__hotspots
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    z-index 1

  &__hotspot
    position absolute
    width 30px
    height 30px
    transform-origin 50% 50%
    +mobile()
      transform scale(1.2)

    &:after
      content ''
      display block
      position relative
      transform-origin top left
      height 3px
      background white
      pointer-events none
      +mobile()
        height 5px
        margin-left 1px

    .hv-human__organ--vein.is-healthy &
      &:nth-child(2)
        left 45.4%
        top 38%
        &:after
          transform rotate(65.8deg)
          width 700%
          top -16px
          left 16px
          +mobile()
            transform rotate(65.8deg) scale(0.85)

      &:nth-child(1)
        right 38%
        bottom 20%
        &:after
          display none

    .hv-human__organ--vein.is-affected &
      &:nth-child(1)
        bottom 40%
        right 42%
        &:after
          display block
          left 13px
          top -12px
          width 410%
          transform rotate(142.2deg)
          +mobile()
            transform rotate(142.2deg) scale(0.85)

      &:nth-child(2)
        bottom 26%
        left 40%
        &:after
          display block
          left 13px
          top -12px
          width 708%
          transform rotate(281.8deg)
          +mobile()
            transform rotate(281.8deg) scale(0.85)

      &:nth-child(3)
        left 46%
        top 31%
        &:after
          display none

    .hv-human__organ--heart.is-affected &,
    .hv-human__organ--heart.is-healthy &
      &:nth-child(1)
        top 47%
        left 33%
        &:after
          transform rotate(33.2deg)
          width 100px
          top -16px
          left 13px
          +mobile()
            transform rotate(33.2deg) scale(0.85)

      &:nth-child(2)
        left 44%
        top 57%
        &:after
          transform rotate(59.7deg)
          width 188px
          top -16px
          left 16px
          +mobile()
            transform rotate(59.7deg) scale(0.85)

      &:nth-child(3)
        top 87%
        left 57.2%

    .hv-human__organ--heart.is-healthy &
      &:nth-child(3)
        &:after
          display none

    .hv-human__organ--heart.is-affected &
      &:nth-child(3)
        &:after
          transform rotate(-96deg)
          width 124px
          top -16px
          left 13px
          +mobile()
            transform rotate(-96deg) scale(0.85)

      &:nth-child(4)
        bottom 30.6%
        left 55.4%
        &:after
          transform rotate(-62.2deg)
          width 200%
          top -50%
          left 44%
          +mobile()
            transform rotate(-62deg) scale(0.85)

      &:nth-child(5)
        left 59.3%
        top 54.8%
        &:after
          display none

    > a
      position relative
      display block
      border none
      width 100%
      height 100%
      transition all 500ms ease
      border-radius 100%
      z-index 3

      .is-active&
        box-shadow none

      +mobile()
        transform scale(1.3)

      html:not(.is-touch) &:hover
        &:before
          transform scale(1)
          box-shadow 0 0 4px 5px rgba(202,15,37,0.25)
          background rgba(236,92,86,1)

          .is-flipped &
            box-shadow 0 0 4px 5px rgba($colorBlue, 0.25)
            background $colorBlue

      &:before
        display block
        content ''
        background rgba(236,92,86,0)
        border 3px solid #FFFFFF
        width 100%
        height 100%
        border-radius 100%
        transform scale(0.4)
        transition all 0.2s ease
        +mobile()
          transition none
        +tablet()
          transition none

        .is-active&
          transform scale(1)
          box-shadow 0 0 4px 5px rgba(202,15,37,0.25)
          background rgba(236,92,86,1)

          .is-flipped &
            box-shadow 0 0 4px 5px rgba($colorBlue, 0.25)
            background $colorBlue

      &:after
        display block
        content ''
        position absolute
        top 50%
        left 50%
        transform translate(-50%,-50%)
        background white
        width 10px
        height 10px
        border-radius 100%
        transition all 0.2s ease

  &.is-magnified
    cursor none
</style>
