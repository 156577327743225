<template>
<div class="hv-topic">
  <div class="hv-topic__inner" ref="topicInner">
    <h1 class="u-h1">{{ c.topics[$route.params.topic].intro.headline }}</h1>
    <div class="hv-topic__image">
      <img v-if="$route.params.topic == 'vorhofflimmern' && showHealthy" src="../assets/image/topics/intro-heart-healthy.svg">
      <img v-else-if="$route.params.topic == 'venenthrombose' && showHealthy" src="../assets/image/topics/intro-vein-healthy.svg">
      <img v-else-if="$route.params.topic == 'vorhofflimmern' && !showHealthy" src="../assets/image/topics/intro-heart-affected.svg">
      <img v-else-if="$route.params.topic == 'venenthrombose' && !showHealthy" src="../assets/image/topics/intro-vein-affected.svg">
    </div>
    <p>{{ c.topics[$route.params.topic].intro.copy }}</p>
  </div>
  <div class="c-slideout  c-slideout--first" :class="{ 'is-foreground' : isSlideoutLeftForeground }" ref="slideoutHandleCauses">
    <div class="c-slideout__inner">
      <span></span>
      <router-link class="c-slideout__btn" :to="slideoutTargetPath('ursachen-und-folgen')">{{ c.common.causes }}</router-link>
      <span></span>
    </div>
  </div>
  <div class="c-slideout  c-slideout--second" :class="{ 'is-foreground' : !isSlideoutLeftForeground }" ref="slideoutHandleFaq">
    <div class="c-slideout__inner">
      <span></span>
      <router-link class="c-slideout__btn" :to="slideoutTargetPath('haeufige-fragen')" >{{ c.common.faq }}</router-link>
      <span></span>
    </div>
  </div>
  <keep-alive>
    <transition mode="out-in" appear @enter="childEnter" @leave="childLeave">
      <router-view ref="routerView" class="hv-topic__child" :key="$route.name.indexOf('detail') != -1 ? $route.params.topic + '-detail' : $route.name + $route.params.topic"></router-view>
    </transition>
  </keep-alive>
</div>
</template>

<script>
import Vue from 'vue'
import { TweenLite, CSSPlugin, Power2 } from 'gsap/all' // eslint-disable-line no-unused-vars
import animateScrollTo from 'animated-scroll-to'
import content from '../mixins/content'
import device from '../mixins/device'
import 'gsap-then'

export default {
  mixins: [content, device],
  data() {
    return {
      isSlideoutLeftForeground: true
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Reset healthy & magnifier toggle
    if (to.params.topic != from.params.topic) {
      this.$store.commit('setHealthy', true)
      this.$store.commit('setMagnifierActive', false)
    }
    next()
  },
  beforeRouteEnter(to, from, next) {
    console.log('Topic beforeRouteEnter')

    // Reset healthy toggle
    if (to.params.topic == from.params.topic) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('setHealthy', true)
      })
    }
  },
  computed: {
    showHealthy() {
      return this.$store.getters.showHealthy
    }
  },
  mounted() {
    this.$currentSlideoutHandle =
      this.$route.name == 'topic-causes'
        ? this.$refs.slideoutHandleCauses
        : this.$refs.slideoutHandleFaq
  },
  methods: {
    slideoutTargetPath(page) {
      if (this.$route.name == 'topic') {
        return '/' + this.$route.params.topic + '/' + page
      } else {
        return '/' + this.$route.params.topic
      }
    },
    updateHandlePosition($page) {
      if (this.isNarrow()) {
        return
      }
      let thisBounds = this.$el.getBoundingClientRect()
      let childPageBounds = $page.getBoundingClientRect()
      let handleTargetPosition = document.scrollingElement.scrollTop + Math.min(
        childPageBounds.top + this.childPageTopPadding - 56,
        thisBounds.bottom - 56
      )
      TweenLite.set(this.$currentSlideoutHandle, { top: handleTargetPosition + 'px' })
    },
    childEnter(el, done) {
      console.log('TopicHome', 'entering', this.$route.name)
      console.log('TopicHome', 'childEnter')
      let isPage = el.className.indexOf('page') !== -1

      console.log('TopicHome', isPage ? 'is page' : 'is not page')

      if (this.isNarrow()) {
        TweenLite.set(this.$refs.topicInner, { display: 'none' })
      }

      if (!isPage) {
        // Only transition in when not page
        this.$refs.routerView.enter().then(done)
        return
      }

      this.isSlideoutLeftForeground = (this.$route.name == 'topic-faq') ? false : true

      if (this.isNarrow()) {
        animateScrollTo(0, {
          element: document.scrollingElement,
          cancelOnUserAction: false
        })
      } else {
        let noTransition = this.$store.state.previousRoute.name == this.$route.name || this.isNarrow()

        if (this.$route.name == 'topic-faq') {
          this.$currentSlideoutHandle = this.$refs.slideoutHandleFaq
          TweenLite.set(this.$refs.slideoutHandleCauses, { zIndex: 3 })
        } else if (this.$route.name == 'topic-causes') {
          this.$currentSlideoutHandle = this.$refs.slideoutHandleCauses
          TweenLite.set(this.$refs.slideoutHandleFaq, { zIndex: 3 })
        }

        // Remove slideout handle bottom position, set top position based on current layout
        let $page = this.$refs.routerView.$el
        let handleBounds = this.$currentSlideoutHandle.getBoundingClientRect()
        this.childPageTopPadding = parseFloat(window.getComputedStyle($page, null).getPropertyValue('padding-top'))
        TweenLite.set(this.$currentSlideoutHandle, { top: handleBounds.top + 'px', bottom: 'auto' })
        this.$refs.routerView.enter(this.updateHandlePosition, noTransition).then(done)
      }
    },
    childLeave(el, done) {
      console.log('TopicHome', 'childLeave')

      let isPage = el.className.indexOf('page') !== -1
      console.log('TopicHome', isPage ? 'is page' : 'is not page')

      let updateCallback = isPage ? this.updateHandlePosition : null
      let noTransition = this.$store.state.previousRoute.name == this.$route.name || this.isNarrow()

      this.$refs.routerView.leave(updateCallback, noTransition).then(function() {
        if (isPage) {
          if (!this.isNarrow()) {
            TweenLite.set(this.$currentSlideoutHandle, { top: 'auto', bottom: '0px' })
            TweenLite.set(this.$refs.slideoutHandleFaq, { zIndex: 4 })
            TweenLite.set(this.$refs.slideoutHandleCauses, { zIndex: 4 })
          }

          Vue.nextTick(() => {
            document.scrollingElement.scrollTop = 0
          })
        }

        if (this.isNarrow()) {
          // TweenLite.set(this.$refs.topicInner, { height: 'auto', overflow: 'auto' })
          TweenLite.set(this.$refs.topicInner, { display: 'block' })
        }

        Vue.nextTick(done)
      }.bind(this))
    },
    prepareEnter() {
      console.log('TopicHome prepare enter')
      let offset = this.$store.getters.isFlipped ? '-100%' : '100%'
      TweenLite.set(this.$el, { x: offset + '%' })
    },
    enter() {
      console.log('TopicHome enter')
      let offset = this.$store.getters.isFlipped ? '-100%' : '100%'
      return TweenLite.fromTo(
        this.$el,
        0.8,
        { x: offset + '%' },
        { x: '0%', ease: Power2.easeOut, clearProps: 'transform' }
      )
    },
    leave() {
      console.log('TopicHome leave')
      let offset = this.$store.getters.isFlipped ? '-100%' : '100%'
      return TweenLite.fromTo(this.$el,
        0.8,
        { x: '0%' },
        { x: offset, ease: Power2.easeIn }
      )
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'

.hv-topic
  display flex
  flex-direction column
  justify-content center
  align-items center
  background white

  +above('m')
    min-height "calc(100vh - %s)" % $headerBarHeight

  &__inner
    padding 2rem
    max-width 600px

    +mobile()
      padding 0
      padding-right 2rem
      padding-left 2rem
      margin-top 2rem
      margin-bottom 6rem
      // min-height 220px

  &__image
    margin 2rem 0

    > img
      max-width 100px

  &__child
    overflow hidden
    width 100%
    height 100%
    position absolute 0
    z-index 3
</style>
