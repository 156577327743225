<template>
  <div class="hv-home">
    <div class="o-panel">
      <h1 class="u-h1">{{ c.home.headline }}</h1>
      <p>{{ c.home.copy }}</p>
    </div>
    <div class="hv-home__sitemap">
      <img class="hv-sitemap__center" src="../assets/image/home/sitemap-center.png">
      <div class="hv-sitemap__part hv-sitemap__part--left">
        <div class="hv-sitemap__group">
          <div class="hv-sitemap__item">Lunge</div>
          <div class="hv-sitemap__item">Bein</div>
        </div>
        <div class="hv-sitemap__item">Vene</div>
        <div class="hv-sitemap__item">
          <router-link to='/venenthrombose'>Tiefe Venenthrombose <br>und Lungenembolie</router-link>
        </div>
      </div>
      <div class="hv-sitemap__part hv-sitemap__part--right">
        <div class="hv-sitemap__item">
          <router-link to='/vorhofflimmern'>Vorhofflimmern<br>und Schlaganfall</router-link>
        </div>
        <div class="hv-sitemap__item">Herz</div>
        <div class="hv-sitemap__item">Gehirn</div>
      </div>
    </div>
  </div>
</template>

<script>
import content from '../mixins/content'

export default {
  mixins: [content]
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/colors'
@require '../assets/style/1-settings/layout'
@require '../assets/style/1-settings/typography'
@require '../assets/style/2-tools/responsive'

$itemMargin = 8%
$itemMarginMobileHorizontal = 2%
$itemMarginMobileVertical = 5%

.hv-home
  width 100%
  display flex
  flex-direction column
  justify-content center
  background-image linear-gradient(-180deg, rgba(255,255,255,0.81) 24%, rgba(255,255,255,0.24) 100%)
  padding 2rem 0 1rem 0

.hv-home__sitemap
  position relative
  display flex
  background url('../assets/image/home/sitemap-bg-mobile.svg') no-repeat
  background-position-x center
  width 100%
  min-height 209px
  margin 1em auto 2rem auto

  +above('m')
    margin 2em auto 2rem auto
    background url('../assets/image/home/sitemap-bg.svg') no-repeat
    background-size contain
    max-width 950px
    min-height 100px
    +below(1000px)
      width 90%

.hv-sitemap
  &__center
    position absolute
    top 2%
    left 50%
    transform translateX(-50%)
    height auto
    responsive(
      width 55px,
      width 86px
    )
    +above('m')
      top 50%
      transform translate(-50%,-50%)
  &__group
    display flex
    flex-direction column

    +above('m')
      margin-right $itemMargin
      .hv-sitemap__item:last-child
        padding 1.5em 1em !important

    +mobile()
      min-width 100px
      margin-right 12%
      margin-top $itemMarginMobileVertical
      flex-direction row

  &__part
    width 50%
    display flex
    flex-direction column
    +above('m')
      height 70px
      flex-direction row
      align-items center
      responsive(
        padding-bottom 20px,
        padding-bottom 0px
      )

    +mobile()
      &--left
        flex-direction column-reverse
        align-items flex-end

      &--right
        justify-content flex-end
        align-items flex-start

  &__item
    border-radius 5px
    color white
    padding 1.5em 1em
    font-family $fontBold
    line-height 1.2
    font-size responsive 9px 12px
    min-width 55px
    display inline-table
    position relative


    +above('m')
      min-width 70px
      .hv-sitemap__group &
        margin-left 0 !important
        &:first-child
          margin-bottom 40%

    +mobile()
      .hv-sitemap__group &:first-child
        margin-right 9%
      .hv-sitemap__part--left > &
        margin-right $itemMarginMobileHorizontal
        &:last-child
          margin-bottom $itemMarginMobileVertical

    .hv-sitemap__part--left > &:last-child,
    .hv-sitemap__part--right > &:first-child
      text-align left
      line-height 1.3
      background transparent
      padding 0
      responsive(
        (width 110px) \
        (height 40px),
        (width 140px) \
        (height 50px)
      )
      a
        position absolute
        z-index 1
        top 50%
        left 50%
        width 100%
        transform translate(-50%,-50%)
        text-align center
      &:before
        content ''
        display block
        position absolute
        width 100%
        height 100%
        border-radius 5px


    .hv-sitemap__part--left &
      background-color #BBD1EC
      &:before
        background-color #578DD0
        box-shadow 0 10px 10px 0 rgba(101,191,249,0.25)
      +above('m')
        &:last-child
          padding-bottom 5em
          margin-left $itemMargin

    .hv-sitemap__part--right &
      background-color #FFB8B7
      margin-left $itemMargin
      &:before
        background-color #E93842
        box-shadow 0 10px 10px 0 rgba(255,78,77,0.25)
      +above('m')
        &:first-child
          margin-left auto
          padding-bottom 5em

      +mobile()
        margin-left $itemMarginMobileHorizontal
        &:not(:first-child)
          margin-top $itemMarginMobileVertical

</style>
