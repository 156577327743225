<template>
<div class="hv-root">
  <header-bar/>
  <main class="hv-main" v-bind:class="[{'hv-main--flipped': (isFlipped), 'is-flipped': (isFlipped), 'is-transitioning': (isTransitioning)}]">
    <stage class="hv-main__stage" :route="$route" ref="stage" />
    <transition appear mode="out-in" @enter="childEnter" @leave="childLeave">
      <router-view class="hv-main__content" ref="routerView"/>
    </transition>
  </main>
  <Navigation/>
  <Footer/>
</div>
</template>

<script>
import { TweenLite, CSSPlugin, Power2 } from 'gsap/all' // eslint-disable-line no-unused-vars
import Stage from '../components/Stage'
import organCanvas from '../components/OrganCanvas'
import HeaderBar from '../components/HeaderBar'
import Navigation from '../components/Navigation'
import Footer from './Footer'
import device from '../mixins/device'

export default {
  data() {
    return {
      isTransitioning: false
    }
  },
  mixins: [device],
  components: {
    HeaderBar,
    Stage,
    Navigation,
    Footer
  },
  computed: {
    isFlipped() {
      return this.$store.getters.isFlipped
    },
    topicKey() {
      if (this.$route.name !== 'topic') {
        return ''
      }
      return this.$route.params.topic
    }
  },
  methods: {
    setStagePosition() {
      console.log('setStagePosition()')
      let offset = this.$store.getters.isFlipped ? 25 : -25
      if (this.isNarrow()) {
        offset = 0
      }
      TweenLite.set(this.$refs.stage.$el, { x: offset + '%' })
    },
    childEnter(el, done) {
      this.isTransitioning = true
      this.$refs.routerView.prepareEnter()

      this.$refs.stage.hideHome().then(() => {
        console.log('Stage hideHome DONE')
        if (!this.isNarrow()) {
          let offset = this.$store.getters.isFlipped ? '25%' : '-25%'
          TweenLite.fromTo(this.$refs.stage.$el, 0.8, { x: '0%' }, { x: offset, ease: Power2.easeInOut })
        }
        this.$refs.stage.enterTopic()
        this.$refs.routerView.enter()
        this.isTransitioning = false
        done()
      })
    },
    childLeave(el, done) {
      this.isTransitioning = true

      if (!this.isNarrow()) {
        let offset = this.$store.getters.isFlipped ? '25%' : '-25%'
        TweenLite.fromTo(this.$refs.stage.$el, 0.8, { x: offset }, { x: '0%', ease: Power2.easeInOut })
      }

      this.$refs.stage.leaveTopic()
      this.$refs.routerView.leave().then(function() {
        this.$refs.stage.showHome().then(function() {
          console.log('Stage showHome DONE')
          this.isTransitioning = false
          done()
        }.bind(this))
      }.bind(this))
    }
  },
  created() {
    console.log('Root created')
    this.currentRoute = this.$store.state.route
    this.addMediaQueryListener(() => {
      if (this.$route.name == 'home') return
      this.setStagePosition()
    })
  },
  watch: {
    $route(to, from) {
      if (to.name.indexOf('topic') !== -1) {
        // Save last visited topic name
        this.$store.commit('setLastTopic', to.params.topic)
        if (!this.isNarrow() && to.params.topic && from.params.topic && (to.params.topic != from.params.topic)) {
          this.setStagePosition()
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.hv-main
  margin 0 auto
  position relative
  overflow-x hidden
  overflow-y hidden
  // min-height calc(100vh - 3rem)
  background-image linear-gradient(90deg, rgba(202, 214, 253, 0.85) 0%, rgba(247, 180, 203, 0.85) 100%)

  // IE needs flex on parent because stage defines min-height ¯\_(ツ)_/¯
  // https://stackoverflow.com/a/44700163
  display flex
  flex-direction column

  +mobile()
    display flex
    flex-direction column

    .hv-main__content
      flex auto

  &__stage
    min-height calc(100vh - 3rem) // Viewport height minus footer height

    +mobile()
      min-height 100vh

  &__content
    // position relative
    background-image linear-gradient(-180deg, rgba(255,255,255,0.81) 24%, rgba(255,255,255,0.24) 100%)
    padding-top 1.8rem
    text-align center

    +above('m')
      position absolute
      height 100%
      width 50%
      left auto
      top 0
      right 0

    .hv-main--flipped &
      right auto
      left 0
</style>
