<template>
  <div class="hv-more">
    <div class="hv-more__inner  o-panel" v-show="content">
      <div class="hv-more__video">
        <img :src="content.video + '.gif'">
      </div>
      <h1 class="u-h1">{{ content.headline }}</h1>
      <p v-html="content.copy"></p>
    </div>
  </div>
</template>

<script>
import { TweenLite, CSSPlugin, Power2 } from 'gsap/all' // eslint-disable-line no-unused-vars
import content from '../mixins/content'

export default {
  mixins: [content],
  computed: {
    content() {
      let mode = this.$store.getters.showHealthy ? 'healthy' : 'affected'
      let probably = this.c.topics[this.$route.params.topic][mode].details[this.$route.params.step - 1].more
      return probably ? probably : null
    }
  },
  methods: {
    enter(onUpdateCallback = null, noTransition = false) {
      console.log('TopicDetailMore enter')
      let offset = 100
      let duration = noTransition ? 0.01 : 0.8
      return TweenLite.fromTo(this.$el, duration,
        { y: offset + '%' },
        { y: '0%', onUpdate: onUpdateCallback, onUpdateParams: [this.$el], ease: Power2.easeOut })
    },
    leave(onUpdateCallback = null, noTransition = false) {
      console.log('TopicDetailMore leave')
      let offset = 100
      let duration = noTransition ? 0.01 : 0.8
      return TweenLite.fromTo(this.$el, duration,
        { y: '0%' },
        { y: offset + '%', onUpdate: onUpdateCallback, onUpdateParams: [this.$el], ease: Power2.easeIn })
    }
  }

}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/colors'

.hv-more
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  display flex
  align-items center
  flex-direction column
  overflow-y scroll
  margin-top 200px
  background-color $colorRed
  +above('m')
    justify-content center

  h1, p
    color white

  +mobile()
    margin-top 180px

  .is-flipped &
    background-color $colorBlue

  &__inner
    max-width 740px

    +mobile()
      max-width 360px

    @media (max-height 799px) and (orientation landscape)
      max-width none

    a
      text-decoration underline

  &__video
    display inline-block
    background white
    margin-bottom 2rem
    width 80%
    max-width 160px
    border-radius 100%
    padding 18px
    box-sizing content-box
    border 18px solid white

    > img
      width 100%

    +mobile()
      max-width 120px

    @media (max-height 568px)
      max-width 90px
      padding 12px
      border 12px solid white

    @media (max-height 799px) and (orientation landscape)
      margin-top 5vh
      max-width 120px
      padding 12px
      border 12px solid white


</style>
