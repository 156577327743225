<template>
  <div class="hv-toggle">
    <input class="hv-toggle__input" id="hv-toggle-input" type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)" />
    <label class="hv-toggle__ui" for="hv-toggle-input">
      <span class="hv-toggle__label">{{ label }}</span>
      <span class="hv-toggle__slider">
        <span></span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: ['value', 'label-on', 'label-off'],
  computed: {
    label() {
      return this.value == true ? this.labelOn : this.labelOff
    }
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'
@require '../../assets/style/1-settings/typography'
$sliderHeight = 28px
$sliderWidth = 64px
$handleSize = 28px

.hv-toggle
  padding 0 2rem
  //min-width 350px
  height 4.5rem
  display flex
  background $colorRed2RGBA
  +below(320px)
    padding 0 1rem

  .is-flipped &
    background $colorPurpleRGBA
  +above('m')
    height 6rem

  &__ui
    display inline-block
    text-align left
    width 100%
    display flex
    align-items center
    justify-content space-between

  &__label
    display block
    line-height 1
    position relative
    top 0.07em
    color white
    font-family $fontBold
    font-size 20px

    +mobile()
      top 0
      padding-right 5px
      font-size 18px

    @media (max-height 500px)
      font-size 14px

  &__slider
    position relative
    display inline-block
    content ''
    width $sliderWidth
    height $sliderHeight
    background-color rgba(255, 255, 255, 0.5)
    border-radius ($sliderHeight/2)
    cursor pointer
    box-shadow 0 0 0 4px rgba(255, 255, 255, 0.2)
    background-image url('../../assets/image/ui/toggle-thrombos.png')
    background-repeat no-repeat
    background-position 78% 50%
    background-size 25%

    &:before
      // Handle
      display block
      content ''
      padding 0
      position absolute
      top 50%
      left 0
      transform translate(0, -50%)
      height $handleSize
      width $handleSize
      background white
      transition transform 0.2s ease
      border-radius 100%
      box-shadow 0 0 3px 2px rgba(80, 80, 80, 0.2)

  &__input
    display none

    &:checked + .hv-toggle__ui .hv-toggle__slider:before
      transform translate(125%, -50%)
</style>
