<template>
  <div class="hv-spinner">
    <img class="hv-spinner__logo" src="../assets/image/header/header-logo.svg" width="139" height="50">
    <p class="hv-spinner__text">Wird geladen &hellip;</p>
    <div class="hv-spinner__loader">Bitte warten ...</div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="stylus">
$spinnerSize = 5rem

.hv-spinner
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  overflow hidden
  display flex
  flex-direction column
  justify-content center
  align-items center

  &__logo
    margin-bottom 1em
    max-width 150px
    width 139px
    height 50px

  &__text
    opacity 0.5

  &__loader,
  &__loader:after
    border-radius 50%
    width $spinnerSize
    height $spinnerSize

  &__loader
    display none
    margin 20px 0
    font-size 10px
    position relative
    text-indent -9999em
    border-top 1.1em solid rgba(255, 255, 255, 0.2)
    border-right 1.1em solid rgba(255, 255, 255, 0.2)
    border-bottom 1.1em solid rgba(255, 255, 255, 0.2)
    border-left 1.1em solid #ffffff
    transform translateZ(0)
    animation load8 1.1s infinite linear

    @keyframes load8
      0%
        transform rotate(0deg)
      100%
        transform rotate(360deg)
</style>
