import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isNavigationOpen: false,
    isContentLoaded: false,
    showHealthy: true,
    isMagnifierActive: false,
    previousRoute: {},
    lastTopic: '',
    content: {}
  },
  getters: {
    currentTopic: state => {
      return state.route.params.topic ? state.route.params.topic : null
    },
    showHealthy: state => {
      return state.showHealthy
    },
    isFlipped: state => {
      let currentTopic = state.route.params.topic ? state.route.params.topic : null
      return currentTopic ? currentTopic == 'venenthrombose' : state.lastTopic == 'venenthrombose'
    }
  },
  mutations: {
    openNavigation(state) {
      state.isNavigationOpen = true
    },
    closeNavigation(state) {
      state.isNavigationOpen = false
    },
    toggleNavigation(state) {
      state.isNavigationOpen = !state.isNavigationOpen
    },
    setHealthy(state, value) {
      state.showHealthy = value
    },
    setMagnifierActive(state, value) {
      state.isMagnifierActive = value
    },
    toggleMagnifier(state) {
      state.isMagnifierActive = !state.isMagnifierActive
    },
    setPreviousRoute(state, value) {
      state.previousRoute = value
    },
    toggleHealthy(state) {
      state.showHealthy = !state.showHealthy
    },
    setLastTopic(state, value) {
      state.lastTopic = value
    }
  }
})
