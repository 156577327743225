<template>
  <router-link :to="toLink" >
    <div class="hv-bubble" :class="['hv-bubble--'+type]">
      <p v-if="isSPAF">Vorhofflimmern<br>& Schlaganfall</p>
      <p v-if="isVTE">Tiefe Venenthrombose<br>& Lungenembolie</p>
    </div>
    <p v-if="isSPAF && caption == 1">Zum interaktiven Herzmodell</p>
    <p v-if="isVTE && caption == 1">Zum interaktiven Venenmodell</p>
  </router-link>
</template>

<script>
  export default {
    props: ['type', 'caption'],
    data() {
      return {
        isSPAF: this.type == 'spaf',
        isVTE: this.type == 'vte'
      }
    },
    computed: {
      toLink() {
        return this.isSPAF ? '/vorhofflimmern' : '/venenthrombose'
      },
    }
  }
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'
@require '../../assets/style/1-settings/layout'
@require '../../assets/style/1-settings/typography'
@require '../../assets/style/2-tools/responsive'

.hv-bubble
  padding 1em
  max-height 238px
  position relative

  + p
    padding-top 1em

  > p
    color white
    font-family $fontBold
    font-size responsive 11px 14px
    line-height 1.2
    width 100%
    padding 0 5%
    margin 0 auto
    position absolute
    top 55%
    left 0
    white-space nowrap

  &--vte,
  &--spaf
    responsive(
      (width 130px) \
      (height 130px),
      (width 150px) \
      (height 150px)
    )

  &--vte
    background url('./../../assets/image/stage/bubble-blue-stage.svg') no-repeat center
    background-size contain

  &--spaf
    background url('./../../assets/image/stage/bubble-red-stage.svg') no-repeat center
    background-size contain

</style>
