<template>
  <header class="hv-header-bar">
    <router-link class="hv-header-bar__logo" to="/"><img src="../assets/image/header/header-logo.svg" alt="Home"></router-link>
    <div class="hv-header-bar__burger" v-bind:class="{ 'is-menu-open': isNavigationOpen }" @click="toggleNavigation()">
      <span></span><span></span><span></span><span></span>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    isNavigationOpen() {
      return this.$store.state.isNavigationOpen
    }
  },
  methods: {
    toggleNavigation() {
      this.$store.commit('toggleNavigation')
    }
  }
}
</script>

<style lang="stylus">
@require '../assets/style/1-settings/layout'

.hv-header-bar
  position absolute
  z-index 99
  width 100%
  display flex
  justify-content space-between
  align-items center
  background transparent
  height $headerBarHeight
  padding 0 4em
  pointer-events none

  +mobile()
    height $headerBarHeightMobile
    padding 0 32px
    background white

  &__logo
    border none
    line-height 0
    font-size 0
    pointer-events auto

    +mobile()
      max-width 90px

    +above('m')
      position relative
      top 10px

      > img
        width 139px
        height 50px

  &__burger
    display block
    transform rotate(0deg)
    transition .5s ease-in-out
    cursor pointer
    width 25px
    height 20px
    opacity 0.7
    pointer-events auto

    span
      display block
      position absolute
      height 2px
      width 100%
      background black
      border-radius 9px
      opacity 1
      left 0
      transform rotate(0deg)
      transition .25s ease-in-out

      &:nth-child(1)
        top 0

      &:nth-child(2), &:nth-child(3)
        top 6px

      &:nth-child(4)
        top 12px

    &.is-menu-open

      span:nth-child(1)
        top 6px
        width 0%
        left 50%

      span:nth-child(2)
        transform rotate(45deg)

      span:nth-child(3)
        transform rotate(-45deg)

      span:nth-child(4)
        top 6px
        width 0%
        left 50%

</style>
