<template>
<div class="hv-html">
  <template v-for="(item, index) in content">
    <span :key="index" v-html="item"></span>
  </template>
</div>
</template>

<script>
export default {
  props: ['content']
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/*'
@require '../../assets/style/7-utils/typography.styl'

.hv-html
  padding-top 2rem
  padding-bottom 2rem
  padding-left 2rem
  padding-right 2rem

  .is-inside &
    background-color rgba(255, 255, 255, 0.8)

  span:last-child
    h1
      margin 0
  h1
    @extend .u-h1

  h2
    @extend .u-h2

  h3
    @extend .u-h1
    font-family $fontRegular
    font-size responsive 18px 24px
    font-range $phoneWidth $desktopMaxWidth
    color $colorRed
    padding-top 1rem
    .is-flipped &
      color $colorBlue

  p
    margin-bottom 2em

  ul
    text-align left
    margin-bottom 1em

    li
      +mobile()
        display flex
      
      &:before
        content '•'
        color $colorRed
        margin-right -0.2em

        .is-flipped &
          color $colorBlue

      > p
        font-size 14px
        display inline-block
        padding-left 0.6em
        vertical-align middle
        margin 0

  span > div
    display flex
    flex-wrap wrap
    justify-content space-between

  span:last-child
    > p,
    > ul
      margin-bottom 0

</style>
