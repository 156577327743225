<template>
  <div class="hv-accordeon">
    <ul class="hv-accordeon__list">
      <li class="hv-accordeon__item" v-for="(item, index) in content" :key="'item-' + index" :class="{ 'is-open': (currentOpenItem === index) }">
        <div class="hv-accordeon__term" @click="onClick(index)"><span class="c-toggle-circle"></span><p>{{ item.term }}</p></div>
        <div class="hv-accordeon__definition" :ref="'definition' + index">
          <p v-html="item.definition"></p>
          <div v-if="item.disclaimer" :class="['hv-disclaimer', { 'is-open-secondary': isDisclaimerOpen }]">
            <button class="hv-disclaimer__btn" @click="toggleDisclaimer()">{{ item.disclaimer.title }} <span class="c-toggle-circle c-toggle-circle--secondary"></span></button>
            <div class="hv-disclaimer__body" v-html="item.disclaimer.body"></div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import expando from 'expando'
import content from '../../mixins/content'
export default {
  props: ['content'],
  mixins: [content],
  data() {
    return {
      currentOpenItem: null,
      isDisclaimerOpen: false
    }
  },
  mounted() {
    if (this.content[0].initiallyOpen == true) {
      this.openItem(0)
    }
  },
  methods: {
    toggleDisclaimer() {
      let $el = this.$refs['definition' + this.currentOpenItem][0]
      if (this.isDisclaimerOpen) {
        expando.collapse($el.querySelector('.hv-disclaimer__body'))
      } else {
        expando.expand($el.querySelector('.hv-disclaimer__body'))
      }
      this.isDisclaimerOpen = !this.isDisclaimerOpen
    },
    onClick(index) {
      if (this.currentOpenItem === index) {
        this.closeItem(index)
        return
      }

      if (this.currentOpenItem !== null) {
        this.closeItem(this.currentOpenItem)
      }
      this.openItem(index)
    },
    closeItem(index) {
      let $el = this.$refs['definition' + index][0]
      expando.collapse($el)
      this.currentOpenItem = null
      this.isDisclaimerOpen = false
    },
    openItem(index) {
      let $el = this.$refs['definition' + index][0]
      expando.expand($el)
      this.currentOpenItem = index
    }
  }
}
</script>

<style lang="stylus">
@require '../../assets/style/1-settings/colors'
@require '../../assets/style/1-settings/layout'
@require '../../assets/style/1-settings/typography'
@require '../../assets/style/2-tools/responsive'

.hv-accordeon
  text-align left
  > h1
    text-align center

  &__item
    margin-bottom 4px

  &__term
    cursor pointer
    display flex
    align-items center
    background $colorRed2RGBA
    padding-left 2rem
    responsive(
      height 68px,
      height 94px
    )

    +mobile()
      padding-left 1rem

    .is-flipped &
      background lighten($colorPurpleRGBA, 20%)

    > p
      margin 0
      color white
      line-height 1.2
      font-size responsive 16px 19px
      +below('l')
        max-width 80%
        margin-left 0.5em

  &__definition
    display none
    overflow hidden
    transition height 0.3s ease
    background white
    height auto

    > p,
    .hv-disclaimer
      padding 1.5rem 2rem 1.5rem
</style>
