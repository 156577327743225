import Vue from 'vue'
import Router from 'vue-router'
import Root from './views/Root'
import Imprint from './views/Imprint'
import TopicHome from './views/TopicHome'
import TopicDetails from './views/TopicDetails'
import TopicDetailMore from './views/TopicDetailMore'
import TopicPage from './views/TopicPage'
import store from './store'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/impressum',
      component: Imprint,
      name: 'imprint'
    },
    {
      path: '/',
      component: Root,
      name: 'home',
      children: [
        {
          path: ':topic(vorhofflimmern|venenthrombose)',
          name: 'topic',
          component: TopicHome,
          children: [
            {
              path: 'detail/:step(\\d)',
              name: 'topic-detail',
              component: TopicDetails,
              children: [{
                path: 'mehr',
                name: 'topic-detail-more',
                component: TopicDetailMore
              }]
            },
            {
              path: 'haeufige-fragen',
              name: 'topic-faq',
              component: TopicPage
            },
            {
              path: 'ursachen-und-folgen',
              name: 'topic-causes',
              component: TopicPage
            }
          ]
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.commit('closeNavigation')
  store.commit('setPreviousRoute', from)
  next()
})

export default router
