import * as PIXI from 'pixi.js'
import EventEmitter from 'event-emitter-es6'
import magnifierFrameAsset from '!url-loader!../assets/image/stage/magnifier-frame.png'

const ORGAN_WIDTH = 358
const ORGAN_HEIGHT = 538
const STAGE_WIDTH = ORGAN_WIDTH * 2
const STAGE_HEIGHT = ORGAN_HEIGHT
const videos = ['heart', 'heart-inner', 'heart-affected', 'heart-affected-inner', 'vein', 'vein-inner', 'vein-affected-inner']
const sprites = {}

class OrganCanvas extends EventEmitter {
  constructor() {
    super()

    console.log('OrganCanvas constructed')
    PIXI.settings.PRECISION_FRAGMENT = 'highp'
    PIXI.utils.skipHello()
    this.PixiApp = new PIXI.Application({
      width: STAGE_WIDTH,
      height: STAGE_HEIGHT,
      transparent: true
    })
    this.PixiApp.stop()

    if (!this.topic) {
      this.topic = 'heart'
    }
  }

  mount($el) {
    $el.appendChild(this.PixiApp.view)
  }

  start() {
    this.render()
    this.PixiApp.start()
  }

  stop() {
    this.PixiApp.stop()
  }

  activateMagnifier() {
    this.previousPosition = null
    this.isMagnifierActive = true
    this.hole.position.x = this.magnifier.position.x = 300
    this.hole.position.y = this.magnifier.position.y = 300
    this.PixiApp.stage.on('mousemove', this.boundMouseMove)
    this.magnifier.on('touchmove', this.boundTouchEvent)
    this.render()
  }

  deactivateMagnifier() {
    this.isMagnifierActive = false
    this.PixiApp.stage.off('mousemove', this.boundMouseMove)
    this.magnifier.off('touchmove', this.boundTouchEvent)
    this.render()
  }

  initialize() {
    // Create containers
    const containers = ['heartContainer', 'veinContainer', 'heartInnerContainer', 'veinInnerContainer', 'magnifierContainer', 'seeThroughContainer']
    for (let i = 0; i < containers.length; i++) {
      let containerName = containers[i]
      this[containerName] = new PIXI.Container()
      this[containerName].width = ORGAN_WIDTH
      this[containerName].height = ORGAN_HEIGHT
    }

    // Define view hierarchy
    this.PixiApp.stage.addChild(this.heartContainer, this.veinContainer, this.seeThroughContainer)

    // Create magnifier
    this.hole = new PIXI.Graphics()
    this.hole.beginFill(0xff0000)
    this.hole.drawCircle(0, 0, 90)
    this.hole.endFill()
    // this.hole.anchor.set(0.5, 0.5)

    let overlay = new PIXI.Container()
    let frame = new PIXI.Sprite.fromImage(magnifierFrameAsset)
    frame.width = 210
    frame.height = 210
    frame.anchor.set(0.5, 0.5)
    overlay.addChild(frame)

    this.magnifier = new PIXI.Container()
    this.magnifier.name = 'magnifier'
    this.magnifier.position.x = STAGE_WIDTH / 2
    this.magnifier.position.y = STAGE_HEIGHT / 2
    this.magnifier.interactive = true
    this.magnifier.interactiveChildren = false
    this.magnifier.addChild(overlay)
    this.PixiApp.stage.addChild(this.magnifier)

    this.seeThroughContainer.addChild(this.hole)
    this.seeThroughContainer.mask = this.hole

    // Prepare magnifier interaction
    this.PixiApp.stage.interactive = true
    this.boundMouseMove = this.onMouseMove.bind(this)
    this.boundTouchEvent = this.onTouchEvent.bind(this)

    this.loadAssets()

    return new Promise((resolve, reject) => {
      this.onInitializeComplete = () => resolve(true)
      this.onInitializeFailed = () => reject(true)
    })
  }

  loadAssets() {
    console.log('OrganCanvas', 'Loading assets ...')
    let loader = PIXI.loader
    for (let i = 0; i < videos.length; i++) {
      let vidId = videos[i]
      loader.add(vidId, 'assets/animations/' + vidId + '.json')
    }
    loader.load(this.onAssetsLoaded.bind(this))
  }

  onAssetsLoaded(loader, resources) {
    console.log('OrganCanvas', 'Assets loaded')
    for (let i = 0; i < videos.length; i++) {
      let vidId = videos[i]
      this.initializeSprite(vidId, resources[vidId].spritesheet.textures)
    }
    this.PixiApp.renderer.plugins.prepare.upload(this.PixiApp.stage, () => {
      console.log('OrganCanvas', 'Textures uploaded')
      this.onInitializeComplete()
    })
  }

  initializeSprite(id, textures) {
    let frameKeys = Object.keys(textures)
    let frames = []

    for (let i = 1; i <= frameKeys.length; i++) {
      let s = String(i)
      while (s.length < 3) {
        s = '0' + s
      }
      let t = textures[id + '-' + s + '.png']
      if (t) {
        frames.push(t)
      }
    }

    let sprite = new PIXI.extras.AnimatedSprite(frames)
    sprite.animationSpeed = 0.4
    sprite.width = ORGAN_WIDTH
    sprite.height = ORGAN_HEIGHT
    sprite.anchor.set(0.5, 0.5)
    sprite.position.x = STAGE_WIDTH * 0.5
    sprite.position.y = STAGE_HEIGHT * 0.5
    sprites[id] = sprite
    sprite.play()

    if (id.indexOf('inner') !== -1) {
      this.seeThroughContainer.addChild(sprite)
    } else if (id.indexOf('heart') !== -1) {
      this.heartContainer.addChild(sprite)
    } else {
      this.veinContainer.addChild(sprite)
    }
  }

  /** INTERACTION */

  onMouseMove(event) {
    let isTouch = event.type.indexOf('touch') !== -1 || event.type == 'tap'
    let offset = isTouch ? -80 : 0

    if (isTouch) {
      this.isTouchDevice = true
    }

    if (this.isTouchDevice && !isTouch) {
      return
    }

    let magBounds = {
      maxX: STAGE_WIDTH - this.magnifier.width * 0.5,
      maxY: STAGE_HEIGHT - this.magnifier.height * 0.5,
      minX: this.magnifier.width * 0.5,
      minY: this.magnifier.height * 0.5
    }

    let userX = event.data.global.x + offset
    let userY = event.data.global.y + offset
    let magnifierX = Math.max(this.magnifier.width * 0.5, Math.min(userX, magBounds.maxX))
    let magnifierY = Math.max(this.magnifier.height * 0.5, Math.min(userY, magBounds.maxY))
    this.magnifier.position.x = magnifierX
    this.magnifier.position.y = magnifierY
    this.hole.position.x = magnifierX
    this.hole.position.y = magnifierY

    // BEGIN POSSIBLE OPTIMIZATION: Run this throttled

    let threshold = 75
    let distance = Math.abs(Math.min(
      Math.min(0, magBounds.maxX + threshold - userX),
      Math.min(0, magBounds.maxY + threshold - userY),
      Math.min(0, userX - magBounds.minX + threshold),
      Math.min(0, userY - magBounds.minY + threshold)
    ))

    if (distance > 1) {
      let scale = 1 - Math.min(threshold, distance) / threshold
      this.magnifier.alpha = scale
      this.seeThroughContainer.alpha = scale
    } else {
      this.magnifier.alpha = 1
      this.seeThroughContainer.alpha = 1
    }

    if (distance > threshold) {
      if (this.topic == 'heart' && Math.abs(Math.min(0, magBounds.maxX + threshold - userX)) > threshold) {
        if (this.previousPosition && this.previousPosition.x < event.data.global.x) {
          this.emit('deactivate-magnifier')
        }
      } else if (this.topic == 'vein' && Math.abs(Math.min(0, userX - magBounds.minX + threshold)) > threshold) {
        if (this.previousPosition && this.previousPosition.x > event.data.global.x) {
          this.emit('deactivate-magnifier')
        }
      }
      else {
        this.emit('deactivate-magnifier')
      }

      this.previousPosition = { x: event.data.global.x, y: event.data.global.y }
    }

    // END OPTIMIZATION
  }

  onTouchEvent(event) {
    switch(event.type) {
      case 'touchmove':
        if (event.data.global.x > this.magnifier.position.x + 100
          || event.data.global.x < this.magnifier.position.x - 100
          || event.data.global.y < this.magnifier.position.y - 100
          || event.data.global.y > this.magnifier.position.y + 100) {
          return
        }
        this.onMouseMove(event)
        break
    }
  }

  render() {
    // TODO: Refactor!
    console.log('OrganCanvas', 'render')

    if (this.topic == 'heart') {
      this.heartContainer.visible = true
      this.veinContainer.visible = false
      sprites['vein'].stop()
      sprites['vein-inner'].stop()
      sprites['vein-affected-inner'].stop()
      if (this.showHealthy) {
        sprites['heart-affected'].stop()
        sprites['heart-affected'].visible = false
        sprites['heart'].visible = true
        sprites['heart'].gotoAndPlay(1)
      } else {
        sprites['heart'].stop()
        sprites['heart'].visible = false
        sprites['heart-affected'].visible = true
        sprites['heart-affected'].gotoAndPlay(1)
      }
    } else {
      this.veinContainer.visible = true
      this.heartContainer.visible = false
      sprites['heart'].stop()
      sprites['heart-inner'].stop()
      sprites['heart-affected'].stop()
      sprites['heart-affected-inner'].stop()
      sprites['vein'].visible = true
      sprites['vein'].gotoAndPlay(1)
    }

    if (!this.isMagnifierActive) {
      this.magnifier.visible = false
      this.seeThroughContainer.visible = false
      sprites['heart-inner'].visible = false
      sprites['heart-affected-inner'].visible = false
      sprites['vein-inner'].visible = false
      sprites['vein-affected-inner'].visible = false
      sprites['heart-inner'].stop()
      sprites['heart-affected-inner'].stop()
      sprites['vein-inner'].stop()
      sprites['vein-affected-inner'].stop()
    } else {
      this.magnifier.visible = true
      this.seeThroughContainer.visible = true
      if (this.topic == 'heart') {
        // Heart
        if (this.showHealthy) {
          sprites['heart-affected-inner'].visible = false
          sprites['heart-affected-inner'].stop()
          sprites['heart-inner'].visible = true
          sprites['heart-inner'].gotoAndPlay(1)
        } else {
          sprites['heart-inner'].visible = false
          sprites['heart-inner'].stop()
          sprites['heart-affected-inner'].visible = true
          sprites['heart-affected-inner'].gotoAndPlay(1)
        }
      }
      else {
        // Vein
        if (this.showHealthy) {
          sprites['vein-affected-inner'].visible = false
          sprites['vein-affected-inner'].stop()
          sprites['vein-inner'].visible = true
          sprites['vein-inner'].gotoAndPlay(1)
        } else {
          sprites['vein-inner'].visible = false
          sprites['vein-inner'].stop()
          sprites['vein-affected-inner'].visible = true
          sprites['vein-affected-inner'].gotoAndPlay(1)
        }
      }
    }
  }
}

export default new OrganCanvas()
