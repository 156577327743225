import { render, staticRenderFns } from "./Accordeon.vue?vue&type=template&id=ae5b3cee&"
import script from "./Accordeon.vue?vue&type=script&lang=js&"
export * from "./Accordeon.vue?vue&type=script&lang=js&"
import style0 from "./Accordeon.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Accordeon.vue"
export default component.exports